import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import instance from '../Services'
import { RequestEndpoints } from '../Services/endpoints'
import CompanyListingRank from './CompanyListingRank'
import './css/CompanyListingRanks.css'
import NavBar from './NavBar'
import PhoneNav from './PhoneNav'
function CompanyListingRanks() {
   const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState([])
        useEffect(() => {
            fetchReportData()
    },[])

    async function fetchReportData() {
        setLoading(true)
         const path = RequestEndpoints.GET_ALL_COMPANIES
        const response = await instance.get(path)
        setLoading(false)
        setCompanies(response.data.data)
    }
    
    
    return (
        <div>
          <div className="hide-on-small"> <NavBar/></div>
         <PhoneNav/>
            {loading ? <div className="progressIndicator"><CircularProgress/> </div> : (
                <table class="companyListingRank__table">
                        <thead>

                            {/* <th>Campany Logo</th> */}
                            <th className="fnTitle">company Name</th>
                            <th  className="fnTitle">Sector</th>
                            {/* <th className="fnTitle">Company Sector</th> */}
                            <th className="fnTitle">GRTI</th>
                        
                        </thead>
                        <tbody>
                          {companies.map((comp, index) => {
                            
                              return (
                                  <CompanyListingRank key={index} name={comp.company?.name??''} Logo={`https://oxfamgrti.com/gtri/cpic?id=${comp.company?.id??''}`} industries={comp?.industrynames??''} sectors={comp?.sectors??''} gtri={comp.company?.value??''} url={comp.company?.url??''}/>
                              )
                          })}

                        
                        </tbody>
                        </table>
            )}
        </div>
    )
}

export default CompanyListingRanks
