import React from 'react'
import { Link } from 'react-router-dom'

function CompanyListingRank({name,Logo, sectors, industries, gtri, url }) {
   
    return (
        <>
            <tr>
         
            <td> 
                <div className="flex">
                    <div className="logoCover" ><img className="company__logo" src={Logo} alt="company logo"/></div><Link to={url} target="_blank">{name}</Link>
                </div>
            </td>
           
            <td>
                {sectors.map((sec, index) => {
                  return <li className="sectors_list" key={index}>{sec.name}</li>
                })}
            </td>
            <td className="gtri">{gtri}</td>
            </tr>
        </>
    )
}

export default CompanyListingRank
