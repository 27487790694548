import React from 'react'
import NavBar from '../NavBar'
import '../css/Report.css'
import PhoneNav from '../PhoneNav'



function RegisterComp() {
    
    return (
        <>
        <div className="hide-on-small"><NavBar/></div>
        <PhoneNav/>
        <div className="report__section">
           
              <iframe title="Register Company" src="https://oxfamgrti.com/gtri/register.jsp"  style={{width: '100%', height: '150vh', border: "0"}} ></iframe>
           
        </div>
      
        </>
    )
}

export default RegisterComp
