import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      //links
      "home": "Home",
      "grti ranking":"GRTI ranking",
      "about us":"About Us",
      "contact us":"Conctact Us",
      "register company":"Register Company",
  
      //home page
      "GTRI": "Ghana Responsible Tax Index (GRTI)",
      "gtri description": "Responsible for measuring and comparing the responsible tax practices of multinational and large companies in Ghana. It describes the construction of the qualitative and quantitative components of the GRTI.",
      "gtri sub description": "The GRTI toolkit is designed with weights that shall be assigned to every applicable component of the pillars that may be related to parameters chosen for each responsibility rating standard.",
      "view report": "View Report",

      //chart
      "Top companies in 2022": "Top companies in 2022: GRTI",

      //secction two
      "Top 10 companies GTRI in 2022": "Top 10 companies GTRI in 2022",
      "Compny name": "Company Name",
      "Indusry": "Indusry",
      "Company Sector": "	Company Sector",
      "View More Report": "View More Report",
        //THE FOUR PILLARS
      "The Four Pillars of GTRI": "The Four Pillars of GTRI",
      "Tax Incentives & Concessions": "Tax Incentives & Concessions",
      "Governance of Coperate Tax Function":  "Governance of Coperate Tax Function",
      "Impact Evaluation of Tax Policy & SDG": "Impact Evaluation of Tax Policy & SDG",
      "Tax Transparency and Reporting": "Tax Transparency and Reporting",
    }
  },
  fr: {
    translation: {
      //links
      "home": "domicile",
      "grti ranking":"GTRI classement",
      "about us":"à propos de nous",
      "contact us":"Nous contacter",
      "register company":"enregistrer l'entreprise",
    
      "GTRI": "Indice de fiscalité responsable du Ghana (GRTI)",
      "gtri description": "Responsable de mesurer et de comparer les pratiques fiscales responsables des multinationales et des grandes entreprises au Ghana. Il décrit la construction des composantes qualitatives et quantitatives du GRTI.",
      "gtri sub description": "La boîte à outils GRTI est conçue avec des pondérations qui doivent être attribuées à chaque composant applicable des piliers qui peuvent être liés aux paramètres choisis pour chaque norme de notation de responsabilité.",
      "view report": "Voir le rapport",

      //chart
      "Top companies in 2022": "meilleures entreprises en 2022: GRTI",
      
      //secction two
      "Top 10 companies GTRI in 2022": "Top 10 des entreprises GTRI en 2022",
      "Compny name": "Nom de la compagnie",
      "Indusry": "Industrie",
      "Company Sector": "Secteur de l'entreprise",
      "View More Report": "Voir plus de rapport",
      //THE FOUR PILLARS
      "The Four Pillars of GTRI": "Les quatre piliers du GTRI",
      "Tax Incentives & Concessions": "Incitations et réductions fiscales",
      "Governance of Coperate Tax Function": "Gouvernance de la fonction fiscale d'entreprise",
      "Impact Evaluation of Tax Policy & SDG": "Évaluation d'impact de la politique fiscale et des SDG",
      "Tax Transparency and Reporting": "Transparence fiscale et reporting",

      //FOOTER
      

    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;

// import i18next from "i18next";
// import { initReactI18next } from "react-i18next";

// const  resources = {
//       en: {
//         translation: {
//           "welcomegrti": "Ghana Responsible Tax Index (GRTI)"
//         }
//       },
//       fr: {
//           translation: {
//               "welcomegrti": "Indice de fiscalité responsable du Ghana (GRTI)"
//           }
//       }
//     };

// i18next.use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: "en", // if you're using a language detector, do not define the lng option
//     fallbackLng: "en",

//     interpolation: {
//       escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//     }
//   });

//   export default i18next