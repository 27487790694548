import React, { useState, useEffect } from 'react'
import './css/Pillars.css'
import Pillar from './Pillar'
import instance from '../Services'
import { RequestEndpoints } from '../Services/endpoints'
import { CircularProgress } from '@material-ui/core'
function Pillars() {
   
    const [pillars, setPillars] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getPillars()
        return () => {
            getPillars()
        }
    }, [])

    const getPillars = async() => {
        setLoading(true)
        const response = instance.get(RequestEndpoints.GET_PILLARS)
        setLoading(false)
      
        setPillars((await response).data?.data)
    }

    return (
        <div className="pillars">
            <div className="container">
                <h1 className="pillar__title fnTitle">The Four Pillars of GRTI</h1>
                {
                    loading ? <div className="progressIndicator"><CircularProgress style={{color: 'red'}}/></div> : ( <div className="pillars__container">
                    {pillars.map(pillar => {
                        return <Pillar title={pillar?.pilar?.name} BgColor={pillar?.pilar?.bgColor} icon={`https://oxfamgrti.com/gtri/pillarpic?id=${pillar?.pilar?.id}`} pillar={pillar}/>
                    })}
                  </div>)
                }
               
              
            </div>
        </div>
    )
}

export default Pillars
