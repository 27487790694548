
import './css/SectionThree.css'
import SectionThreeSub from './SectionThreeSub'
function SectionThree() {
       const items = [
        
        {
            title: "Target Population",
            text: "The target population of the development of the methodology includes all sectors within the formal setting of the Ghanaian economy.",
            icon: process.env.PUBLIC_URL + "/images/marketing-icon.png",
            activeColor: false,
            altName: "Target Population"
            
        },
        {
            title: "Sample",
            text: "The sample for the development of the toolkit was made up of companies from the following sectors: telecommunication, hospitality, extractives, manufacturing, financial, and energy.",
            icon: process.env.PUBLIC_URL + "/images/businessstrategy-icon.png",
            activeColor: false,
            altName: "Sample"
            
        },
        {
            title: "Sampling Techniques",
            text: "Sectors were selected at random. Organisations from the various sectors were also selected based on random and purposive sampling depending on the number of companies in the sector. In sectors where the number of companies were few, the major players in those sectors were selected.",
            icon: process.env.PUBLIC_URL + "/images/businessstrategy-icon.png",
            activeColor: false,
            altName: "Techniques"
            
        },
        {
            title: "Source of Data",
            text: "We used both primary and secondary data sources. The primary source was mainly through interviews and questionnaires. Secondary data included data from agencies such as the Ghana Revenue Authority (GRA), Registrar General’s Department, the Ministry of Finance (MoF), reports, articles, newsletters and journals from various companies and the Ghana Stock Exchange",
            icon: process.env.PUBLIC_URL + "/images/businessstrategy-icon.png",
            activeColor: false,
            altName: "Data"
            
        },
        {
            title: "Data Analysis",
            text: "A simple statistical analysis was done using Microsoft Excel. This included descriptive statistics of the questionnaire given out during the first forum.",
            icon: process.env.PUBLIC_URL + "/images/businessstrategy-icon.png",
            activeColor: true,
            altName: "Analysis"
            
        },
        {
            title: "Ethical Consideration",
            text: "This research was done in accordance with the laws of Ghana, the Data Protection Act, 2012 (Act 843) and DNI’s policy on ethical consideration. During the preparation of this report, no intellectual property was used without proper authorisation and that all persons involved gave their consent. All sensitive information gathered were handled under strict confidentiality and privacy. ",
            icon: process.env.PUBLIC_URL + "/images/businessstrategy-icon.png",
            activeColor: false,
            altName: "Consideration"
            
        },
    ]
 
 
    return (
        <div className="section__three">
            <div className="container">
            <h1 className="title__head fnTitle">Approach to developing the GRTI toolkit</h1>
            <div className="grid">
                {items.map((item) => {
                  return <SectionThreeSub title={item.title} text={item.text} activeColor={item.activeColor} icon={item.icon}/>
                })}
            </div>
            </div>
        </div>
    )
}

export default SectionThree
