import './css/NavBar.css'
import {Link, useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
function NavBar() {
    const history = useHistory()
    const {t} = useTranslation()
    return (
        <div className="nav">
            <div className="container flex">
                <div className="logo__menuLinks">
                    <img onClick={() => history.push('/')} className="logo" style={{cursor: 'pointer'}} src="/oxfam-logo.jpg" alt="Oxfarm Logo"/>
                    <div className="memu__links">
                         <ul>
                            <li><Link to="/">{t('home')}</Link></li>
                            <li><Link to="/report">{t('grti ranking')}</Link></li>
                            <li><Link to="/about">{t('about us')}</Link></li>
                            <li><Link to="/contact">{t('contact us')}</Link></li>
              
                         </ul>
                    </div>
                </div>
                
                <Link to="/register" className="cus_button registerBtn">{t('register company')}</Link>
               

                {/* <select className="languageSelector" onChange={(e) => i18n.changeLanguage(e.target.value)}>
                    <option value="en">en</option>
                    <option value="fr">fr</option>
                </select> */}
                

            </div>
        </div>
    )
}

export default NavBar
