import React from 'react'
import { Link } from 'react-router-dom'

function AboutShowCase() {
    return (
        <>
           <div className="aboutShowCase">
             <div className="grid container">
                    <div className="side__one">
                   <div className="items">
                        <div className="emptey__box"></div>
                    <div className="imgCover">
                           <img src="oxfam-img.jpg" className="about__showcase__image" alt="aboutImg"/>
                    </div>
                   </div>
                </div>
                <div className="side__two">
                    <div className="items">
                                <h1 className="about__showcase__title fnTitle">About Oxfam GRTI</h1>
                    <p className="about__showcase__desciription">The Ghana Responsible Tax Index (GRTI) is a suggested toolkit developed on the basis of international standards that would be used to rank enterprises-companies based on the responses to their respective tax compliance obligations in Ghana. Tax responsibility does not only imply payment of all appropriate taxes, but the filing of required returns and on time. The GRTI’s focus is to establish a set of principles that should be attractive for taxpayer adoption and so makes for voluntary assessment of taxpayers in their bid to identify with the classification of responsible nation building. The objective is to help Ghana achieve tremendous success in the realisation of revenue mobilisation for the ultimate revenue parameter in the sustainable development goal agenda.</p>
                    <div style={{marginTop: '30px'}}>
                        <Link className="cus_button about__showcase__button">View Reports</Link>
                    </div>
                    </div>
                </div>
             </div>
                </div>
             
        </>
    )
}

export default AboutShowCase
