import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {Bar} from 'react-chartjs-2'
import instance from '../Services'
import {useTranslation} from 'react-i18next'
function Chart() {
       const [companies, setCompany] = useState([])
       const [loading, setLoading] = useState(false)
       const { t } = useTranslation()
    useEffect(()=>{
        getTopFiveCompanies()
    },[])

  

    const getTopFiveCompanies = async() => {
        setLoading(true)
        const path = "/gtri/grtidata?from=2022&to=2022"
        const response = await instance.get(path);
        setLoading(false)
      
        setCompany(response.data.data.slice(0,5))
      
    }
    let label = t('Top companies in 2022')
    const values = companies.map((comp, index) => {
                    return comp.company.value;
                })
    return (
        <div style={{}}>
            {loading ? <div className="progressIndicator"><CircularProgress style={{color: 'var(--primaryColor)'}}/></div> : ( <Bar style={{height:'300px'}}
             data={{
                // labels: ['company1', 'company2', 'company3', 'company4', 'company5'],
                labels: companies.map((comp, index) => {
                    return comp.company.name
                }),
                datasets:[
                    {
                        label:label,
                        data: values,
                        // data: values.sort((a,b) => (a - b )),
                        backgroundColor: 'lightblue',
                        borderWidth: 20
                    }
                ]
            }}
            options={{
                scales:{
                    xAxes: [
                        {
                            scaleLabel: {
                                labelString: 'GTRI',
                                display: true
                            }
                        }
                    ],
                    yAxes:[
                    
                        {
                              scaleLabel: {
                                labelString: 'Companies',
                                display: true
                            },
                            ticks:{beginAtZero:true}
                        }
                    ]
                }
            }}
            >

            </Bar>)}
           
        </div>
    )
}

export default Chart
