import React, { useState } from 'react'
import { FcAbout, FcBusinessman, FcDocument, FcSmartphoneTablet, FcSms } from 'react-icons/fc'
import NavBar from '../NavBar'
import '../css/Contact.css'
import PhoneNav from '../PhoneNav'
import instance from '../../Services'
import { RequestEndpoints } from '../../Services/endpoints'
import {useHistory} from 'react-router-dom'
import { toast } from 'react-toastify'

let initialFormData = {email: '', firstname: '', lastname: '', subject:'', message: ''}
function Contact() {
    const [formData, setFormData] = useState(initialFormData)
    const history = useHistory()

    const onChangeValue = (e) => {
        setFormData(prevState => {
            return {
                ...prevState, [e.target.name] : e.target.value
            }
        })
    }
    const sendMessage = async () => {
        if(formData.email === '' ){
            toast.error('Email cannot be empty')
            return
        }else if( formData.lastname === '' ){
            toast.error('Lastname cannot be empty')
            return
        }else  if(formData.firstname === ''){
            toast.error('Firstname cannot be empty')
            return
        }else  if( formData.subject === '' ){
            toast.error('Subject cannot be empty')
            return
        }else  if(formData.message === '' ){
            toast.error('Message cannot be empty')
            return
        }else{
            const response = await instance.get(RequestEndpoints.SEND_MESSAGE,   {
                email: formData.email,
                firstname: formData.firstname,
                lastname: formData.lastname,
             //    phone: formData.phone,
                subject: formData.subject,
                message: formData.message
            })
         
                 if(response.status === 200){
                     setFormData(initialFormData)
                     history.push('/?message-sent')
                     toast('Your message had been sent. We will get back to you. Thank you', {autoClose: 8000})
                 }else{
                     toast.success('error while sending message')
                 }
        }
       
    }
    return (
        <>
         <div className="hide-on-small"> <NavBar/></div>
         <PhoneNav/>
            <div className="conatact-page">
                <div className="container">
                    <div className="grid__container">
                        <div className="companyInfo" style={{background:"url('oxfam-img.jpg') no-repeat center center/cover"}}>
                            <div className="overlay">
                                <div className="items">
                                    <div className="locItems">
                                        <div className="icon"><FcSmartphoneTablet/></div>
                                        <div>
                                            <p className="location__header">Office address: </p>
                                            <p>435/12/155 Nii Aku-Ashong Street, Off Gifty Homes, </p>
                                            <p>Adjiringanor - Accra.</p>
                                        </div>
                                    </div>
                                    <div className="locItems">
                                        <div className="icon"><FcSmartphoneTablet/></div>
                                        <div>
                                            <p className="location__header">Contact us through: </p>
                                            {/* <p>+254 (0) 20 2820000</p>
                                            <p>+254 722 200417</p> */}
                                            <p>+233 (0)320 901373</p>
                                            <p>+233 (0)320 901374</p>
                                        </div>
                                    </div>
                                    <div className="locItems">
                                        <div className="icon"><FcSmartphoneTablet/></div>
                                        <div>
                                            <p className="location__header">Contact Mail: </p>
                                            <p>info@oxfamgrti.org</p>
                                            <p>oxfamgrti@gmail.org</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form__message">
                            <div className="form">
                                <h2 className="title fnTitle">Send Us Your Message</h2>
                                <div className="form-data">
                        <div className="icon__input">
                            <div className="icon">
                                <FcSms/>
                                <p>Email Address <span>*</span></p>
                                </div>
                            <input placeholder="Email Adress" name="email" value={formData.email} onChange={(e) => onChangeValue(e)}/>
                        </div>
                        
                        <div className="grid">
                             <div className="icon__input">
                            <div className="icon">
                                <FcBusinessman/>
                                  <p>FirstName <span>*</span></p>
                                </div>
                            <input placeholder="FirstName" name="firstname" value={formData.firstname} onChange={(e) => onChangeValue(e)}/>
                          </div>
                             <div className="icon__input">
                            <div className="icon">
                                <FcBusinessman/>
                                  <p>LastName <span>*</span></p>
                                </div>
                            <input placeholder="LastName" name="lastname" value={formData.lastname} onChange={(e) => onChangeValue(e)}/>
                          </div>
                        </div>
                         {/* <div className="icon__input">
                            <div className="icon">
                                <FcPhoneAndroid/>
                                 <p>Phone Number </p>
                                </div>
                            <input placeholder="Phone Number" name="phone" value={formData.phone} onChange={(e) => onChangeValue(e)}/>
                        </div> */}
                        <div className="icon__input">
                            <div className="icon">
                                <FcAbout/>
                                 <p>Subject <span>*</span></p>
                            </div>
                            <input placeholder="Subject/Title" name="subject" value={formData.subject} onChange={(e) => onChangeValue(e)}/>
                        </div>
                       
                        <div className="message">
                             <div className="icon">
                                <FcDocument/>
                                 <p>Message <span>*</span></p>
                            </div>
                            <textarea  rows="5" cols="5" name="message" placeholder="Start typing your message here" value={formData.message} onChange={(e) => onChangeValue(e)}>

                            </textarea>
                        </div>
         
                        <div className="button">
                            <button onClick={sendMessage} className="cus_button">Send Message</button>
                        </div>
                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
