import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {FaAlignCenter} from 'react-icons/fa'

import './css/PhoneNav.css'

function PhoneNav() {
const [open, setOpen] = useState(false)

//   transform: translateX(0);

 let display = open ? 'block': 'none'
 let opacity = open ? '1': '0'
 let scale = open ? 'scale(1)': 'scale(0.9)'
    const openMenu = () => {
       setOpen(!open)
    }

    return (
        <div className="phone__navigation hide-on-large">
            <nav>
                 <div className="">
               
                    <div className="navBar">
                            <div className="logo__and__menu container">
                                    <img className="logo" style={{cursor: 'pointer', width: '100px'}} src="/oxfam-logo.jpg" alt="Oxfarm Logo"/>
                            <FaAlignCenter className="menu__icon" onClick={openMenu}/>
                            </div>
                    </div>
             
                    <div className="memu__dropdown" style={{display: `${display}`,opacity: `${opacity}`, transform: `${scale}`}}>
                         <ul className="container">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/report">GRTI ranking</Link></li>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
              
                         </ul>
                        <div className="container"><Link to="/register" className="registerBtn">Register Company</Link></div>
                    </div>
                  
               
            </div>
            </nav>
        </div>
    )
}

export default PhoneNav
