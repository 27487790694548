import React from 'react'
import NavBar from './NavBar'
import PhoneNav from './PhoneNav'
import Pillars from './Pillars'
import SectionTwo from './SectionTwo'
import ShowCase from './ShowCase'

function HomePage() {
    return (
        <div>
     <div className="hide-on-small">
          <NavBar />
     </div>
     <PhoneNav/>
     <ShowCase/>
     <SectionTwo/>
     <Pillars/>
     
     <div className="footer">
          <div className="container">
                
          </div>
     </div>
        </div>
    )
}

export default HomePage
