import React from 'react'
import NavBar from '../NavBar'
import '../css/Report.css'
import PhoneNav from '../PhoneNav'



function Report() {
    
    return (
        <>
        <div className="hide-on-small"><NavBar/></div>
        <PhoneNav/>
        <div className="report__section">
              <iframe title="Report" src="/gtri"  style={{width: '100%', height: '150vh', border: "0"}} ></iframe>    {/* <div data-bind="ojModule:{name: router.stateId,viewName:router.stateId}" ></div> */}
        </div>
      
        </>
    )
}

export default Report
