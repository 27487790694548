import axios from 'axios'

// let baseURL = "https://gtri.motesgh.com"
let baseURL = "https://oxfamgrti.com"

const instance = axios.create({
  baseURL: baseURL,
  headers: {
      'X-Requested-With': 'XMLHttpRequest'
  }
})

export default instance