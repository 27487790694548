import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import instance from '../Services'
import Company from './Company'
import './css/SectionTwo.css'
import {CircularProgress} from '@material-ui/core'
import {useTranslation} from 'react-i18next'


const firstAid = process.env.PUBLIC_URL + 'oxfam-img.jpg'

function SectionTwo() {
    const history = useHistory()
    const {t} = useTranslation()

    const [companies, setCompany] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getTopFiveCompanies()
    }, [])

    const getTopFiveCompanies = async () => {
        setLoading(true)
        const path = "/gtri/grtidata?from=2022&to=2022"
        const response = await instance.get(path);
        setLoading(false)
        setCompany(response.data.data.slice(0, 10))
    }

   

    return (
        <div className="section__two" style={{ background: `url('${firstAid}') no-repeat center center/cover` }}>
            <div className="overlay">
                <div className="topYellow"></div>
                {loading ? <div className="progressIndicator"><CircularProgress style={{color:'white'}} /></div> : <div className="container">
                    <h1 className="title">{t("Top 10 companies GRTI in 2022")}</h1>
                    <hr className="hr" />
                    <div className="table__cover">
                        <table class="company__table">
                            <thead>
                              
                              
                                <th className="fnTitle">{t("Compny name")}</th>
                               
                                <th className="fnTitle">Sector</th>
                                
                                <th className="fnTitle">GRTI</th>

                            </thead>
                            <tbody>
                                {companies.map((comp, index) => {
                                    
                                    return (
                                        <Company key={index} name={comp.company?.name ?? ''} Logo={`https://oxfamgrti.com/gtri/cpic?id=${comp.company?.id ?? ''}`} industries={comp.industrynames} sectors={comp.sectors} gtri={comp.company?.value ?? ''} url={comp.company?.url ?? ''} />
                                    )
                                })}


                            </tbody>
                        </table>
                    </div>
                    <div className="report__button">
                        {/* <button onClick={() => history.push('/companies')} className="cus_button ">{t("View More Report")}</button> */}
                        <button onClick={() => history.push('/report')} className="cus_button ">{t("View More Report")}</button>
                    </div>
                </div>
                }
                
            </div>
        </div>
    )
}

export default SectionTwo
