import React from 'react'

function SectionThreeSub({title, icon, text, activeColor, altName, }) {
    return (
                 <div className={`box ${activeColor && 'activeBg'}`}>
                  
                    <div className="content">
                    <div className="icon__nd__header">
                        <img src={icon} alt={altName}/> 
                         <h2 className="title">{title}</h2>
                    </div>
                        <p className="desc">{text}</p>
                    </div>
                   {activeColor && <div className="topYellow"></div>} 
                </div>
    )
}

export default SectionThreeSub
