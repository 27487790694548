
import './App.css';
import {Switch, Route} from 'react-router-dom'
import HomePage from './Components/HomePage';
import AboutUs from './Components/pages/AboutUs';
import Contact from './Components/pages/Contact';
import Report from './Components/pages/Report';
import Footer from './Components/Footer';
import CompanyListingRanks from './Components/CompanyListingRanks';
import RegisterComp from './Components/pages/RegisterComp';
import PillarDetailed from './Components/pages/pillarDetailed';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()
function App() {
  return (
    <div className="App">
    <Switch>
       <Route path="/" exact component={HomePage}/>
       <Route path="/pillarDetailed"  component={PillarDetailed}/>
       <Route path="/about" component={AboutUs}/>
       <Route path="/contact" component={Contact}/>
       <Route path="/report" component={Report}/>
       <Route path="/companies" component={CompanyListingRanks}/>
       {/* <Route path="/register" component={RegisterCompany}/> */}
       <Route path="/register" component={RegisterComp}/>
      
    </Switch>
    <Footer/>
    
    </div>
  );
}

export default App;
